/*
 * Loads the page data from the website API.
 * If no page is found we fall back to the 404 CMS page.
 *
 * @returns {Object} Containing the page and the fetchPage method.
 */
export const useGroupsPages = async () => {
	const groupsPages = useState('groupsPages', () => {});

	const setGroupsPages = (data) => {
		groupsPages.value = data;
	};

	const fetchGroupsPages = async () => {
		const { locale } = useI18n();
		const { data: groupsPages } = await useWebsiteFetch('pages', {
			api: 'group',
			// ID 8 = Vondel Magazine, ID 25 = Jobs
			query: { language: locale.value === 'de' ? 'en' : locale.value, pageIds: '8,25' },
			key: `${locale.value === 'de' ? 'en' : locale.value}/groupsPages`,
		});

		setGroupsPages(groupsPages);
	};

	return {
		groupsPages,
		fetchGroupsPages,
	};
};
